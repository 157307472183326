import { Canvas } from "@react-three/fiber";
import { useGLTF, Stage, PresentationControls, Stars } from '@react-three/drei';
import './App.css';

function Model(props) {
  const { scene } = useGLTF("./models/slave1.glb");
  return <primitive object={scene} {...props} />
}

function App() {
  return (
    <div className='App'>

      <Canvas >
        <PresentationControls speed={1.5} global zoom={.5} polar={[-0.1, Math.PI / 4]}>
          <pointLight color="#f6f3ea" position={[2, 0, 5]} intensity={20} />
          <Stars
            radius={300}
            depth={60}
            count={20000}
            factor={7}
            saturation={0}
            fade={true}
          />

          <Model scale={0.01} />

        </PresentationControls>
      </Canvas>

    </div>
  );
}

export default App;
